import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import {
  FaFacebookF,
  FaHandPointRight,
  FaInstagram,
  FaRegEye,
  FaRegHandshake,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { client, urlFor } from "../client";
import { Link, NavLink, useParams } from "react-router-dom";
import { images } from "../constants";

import { FaArrowLeft } from "react-icons/fa";

import "./scss/Gallery.scss";

import {
  Services,
  Statement,
  About as AboutSection,
  Brands,
} from "../container";

import { Helmet } from "react-helmet";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { BiCaretRight, BiRightArrow } from "react-icons/bi";
import videoBgMp4 from "../assets/our-stock-is-rising.mp4";
import { AiOutlineLineChart } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

function Img() {
  const [purpose, setPurpose] = useState(null);
  // const [services, setServices] = useState(null);
  // const {slug} = useParams();

  const [singleMedia, setSingleMedia] = useState(null);

  const videoRef2 = useRef(null);

  const { slug } = useParams();
  const [scroll, setScroll] = useState(false);

  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [imageToShow, setImageToShow] = useState("");
  const [textToShow, setTextToShow] = useState("");
  const scrollRef = React.useRef(null);

  const scroll2 = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 40);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(false);
      });
    };
  }, []);

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"]{
            name,
            _id,
            slug,
            facebookLink,
            linkedinLink,
            description,
            bio,
            date,
            thumbnail{
                asset->{
                    _id,
                    url
                }
            },
            video{
                asset->{
                    _id,
                    url
                }
            },
            imageUrl{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl2{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl3{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl4{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl5{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl6{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl7{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl8{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl9{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl10{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl11{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl12{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl13{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl14{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl15{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl16{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl17{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl18{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl19{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl20{
                asset->{
                    _id,
                    url
                },
                alt
            },
            video{
                asset->{
                    _id,
                    url
                },
                alt,
                thumbnail{
                  asset->{
                      _id,
                      url
                  }
                }
            },
            video2{
                asset->{
                    _id,
                    url
                },
                alt,
                thumbnail{
                  asset->{
                      _id,
                      url
                  }
                }
            },
            video3{
                asset->{
                    _id,
                    url
                },
                alt,
                thumbnail{
                  asset->{
                      _id,
                      url
                  }
                }
            },
            video4{
                asset->{
                    _id,
                    url
                },
                alt,
                thumbnail{
                  asset->{
                      _id,
                      url
                  }
                }
                
            },
            video5{
                asset->{
                    _id,
                    url
                },
                alt,
                thumbnail{
                  asset->{
                      _id,
                      url
                  }
                }
            },
            images[] {
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageTitle,
            imageDescription,
            videos[]{
                asset->{
                    _id,
                    url
                },
                vidtxt
            },

        }`
      )
      .then((data) => setSingleMedia(data[0]))
      .catch(console.error);
  }, [slug]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "purpose" && slug.current == 'purpose']{
            name,
            _id,
            slug,
            preheading,
            heading,
            description,
            name2,
            video, 
            thumbnail,
            preheading2,
            heading2,
            description2,

            images[]{
                asset->{
                    _id,
                    url
                },
                alt
            },
            imageUrl{
                asset->{
                    _id,
                    url
                }
            },
            imageUrl2{
                asset->{
                    _id,
                    url
                }
            }
        }`
      )
      .then((data) => setPurpose(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singleMedia)
    return (
      <div className="preloader">
        <div className="status"></div>
      </div>
    );

  if (!purpose)
    return (
      <div className="preloader">
        <div className="status"></div>
      </div>
    );
  // if(!services) return <div>Loading.....</div>

  console.log(singleMedia);

  console.log(singleMedia.images);

  const mediaImgs = [singleMedia.imageUrl, singleMedia.imageUrl2];

  console.log(mediaImgs);



  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = mediaImgs.indexOf(imageToShow);
    if (currentIndex >= mediaImgs.length - 1) {
      let nextImage = mediaImgs[0];
      setImageToShow(nextImage);
    } else {
      let nextImage = mediaImgs[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = mediaImgs.indexOf(imageToShow);
    if (currentIndex <= 0) {
      let nextImage = mediaImgs[mediaImgs.length - 1];
      setImageToShow(nextImage);
    } else {
      let nextImage = mediaImgs[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };


  const showContent = (mediaImgs) => {
    showImage(mediaImgs);
    // showImageTxt(mediaImgs.alt);
  };

  
  const showImage = (mediaImgs) => {
    //set imageToShow to be the one that's been clicked on
    setImageToShow(urlFor(mediaImgs));
    setTextToShow(mediaImgs.alt);
    //set lightbox visibility to true
    setLightBoxDisplay(true);
  };

  console.log(imageToShow);

  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  // const showImageTxt = (mediaImgs) => {
  //   setTextToShow(mediaImgs);
  // };

  console.log(textToShow);

  console.log(singleMedia);
  return (
    <>
      <Helmet>
        <title>{singleMedia.name}</title>
        <meta name="description" content={singleMedia.description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          // href="%PUBLIC_URL%/logo152.png"
          href={urlFor(singleMedia.imageUrl).width(800).height(800).url()}
        />
      </Helmet>

      <section id="home-4">
        <video
          className="home-bg-video"
          src={videoBgMp4}
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div id="home-overlay-2"></div>
      </section>

      <div className="map-color-3">
        <div className="row" style={{ marginRight: "0" }}>
          <div className="home-headings tools-p-align">
            <div className="horizontal-heading influencer-name">
              <h1 className="home-headings-2 influencer-name-h1">
                <span className="influencer-name-span">{singleMedia.name}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section id="portfolio">
        <div className="isotope-filters">
          <div className="container"></div>

          {/* Portfolio items Wrapper */}

          <section
            id="about2"
            style={{
              height: "calc(100vh - 140px)",
              display: "table",
              width: "100%",
            }}
          >
            <div
              className="about-01"
              style={{ display: "table-cell", verticalAlign: "middle" }}
            >
              {/* <div className='show-button-page' style={{background: 'none', marginBottom: '0'}}>   

                                    <div class="basic-card basic-card-aqua " style={{margin: '0 auto', backgroundColor: 'none'}}>
                                        <div class="card-link-b" style={{padding: '7px', borderTop: '0', }}>
                                            <NavLink to='/media/gallery' title="Read Full" style={{backgroundColor: 'none'}}>
                                            <FaArrowLeft />
                                            <span style={{marginLeft: '10px'}}>Back to All Images</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div> */}

              <div
                className="container"
                style={{ marginBottom: "60px", marginTop: "60px" }}
              >
                <div
                  className="horizontal-heading main-about"
                  style={{ color: "#000" }}
                >
                  <h2>
                    {" "}
                    <strong></strong>
                    {singleMedia.name}
                  </h2>
                  <h5 style={{ color: "#000" }}>{singleMedia.date}</h5>
                </div>

                <hr style={{ margin: "1.5rem", border: "2px solid #213805" }} />

                <section id="portfolio-wrapper">
                  <div className="container-fluid center-pics">
                    <div className="row no-gutters">
                      <div
                        className="isotope-filters"
                        style={{ paddingLeft: "0", paddingRight: "0" }}
                      >
                        {singleMedia.imageUrl && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}


                        {singleMedia.imageUrl2 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl2)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl2)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl2.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl2.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}


                        {singleMedia.imageUrl3 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl3)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl3)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl3.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl3.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl4 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl4)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl4)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl4.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl4.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl4 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl4)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl4)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl4.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl4.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl5 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl5)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl5)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl5.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl5.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl6 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl6)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl6)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl6.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl6.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl7 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl7)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl7)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl7.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl7.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl8 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl8)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl8)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl8.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl8.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl9 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl9)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl9)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl9.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl9.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl10 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl10)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl10)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl10.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl10.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl11 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl11)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl11)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl11.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl11.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl12 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl12)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl12)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl12.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl12.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl13 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl13)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl13)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl13.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl13.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl14 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl14)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl14)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl14.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl14.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl15 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl15)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl15)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl15.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl15.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl16 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl16)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl16)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl16.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl16.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl17 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl17)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl17)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl17.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl17.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl18 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl18)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl18)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl18.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl18.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl19 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl19)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl19)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl19.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl19.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {singleMedia.imageUrl20 && (
                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">
                            {/* {console.log(img.alt)} */}
                            <div
                              className="portfolio-item portfolio-pic no-underline"
                              onClick={() => showContent(singleMedia.imageUrl20)}
                            >
                              <img
                                // onClick={() =>
                                //   showImage(
                                //     urlFor(singleMedia.imageUrl)
                                //       .height(800)
                                //       .width(800)
                                //   )
                                // }
                                src={urlFor(singleMedia.imageUrl20)
                                  .height(800)
                                  .width(800)}
                                className="img-responsive"
                                alt={singleMedia.imageUrl20.alt}
                              />

                              <div className="portfolio-item-overlay margin-top-g">
                                <div className="portfolio-item-details text-center">
                                  <p style={{ textTransform: "initial", fontWeight: '600', fontSize: '13px' }}>
                                    {singleMedia.imageUrl20.alt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                </section>

                <hr style={{ margin: "1.5rem", border: "2px solid #213805" }} />
              </div>
            </div>
          </section>
        </div>
      </section>

      {lightboxDisplay ? (
        <div id="outterlightbox">
          <div id="lightbox" onClick={hideLightBox}>
            {/* <button className="lightbox-button" onClick={showPrev}>
              ⭠
            </button> */}
            <div style={{position: 'relative'}}>
              {/* <button type="submit" class="lightboxbtn"> */}
                <span className="lightboxclose" style={{position: 'absolute', top: '10px', right: '10px', zIndex: '9999', color: ''}}>&times;</span>
              {/* </button> */}
              <img id="lightbox-img" className="lightboximg" src={imageToShow}></img>
            </div>


            {/* <button className="lightbox-button" onClick={showNext}>
              ⭢
            </button> */}

            {/* <div class="break"></div> */}


          {/* <div className="lightbox-button">
            {textToShow}
            testing
          </div> */}
            <p style={{fontWeight: '700', color: '#dcdcdc', fontSize: '18px', marginTop: '20px'}}>
            {textToShow}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {singleMedia.video && (
        <>
          
            <>
              <div className="map-color-3">
                <div className="row" style={{ marginRight: "0" }}>
                  <div className="home-headings tools-p-align">
                    <div className="horizontal-heading influencer-name">
                      <h1 className="home-headings-2 influencer-name-h1">
                        <span className="influencer-name-span">{singleMedia.video.alt}</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <section id="portfolio">
                <div className="isotope-filters">
                  <div className="container"></div>


                  <section
                    id="about2"
                    style={{
                      minHeight: "calc(100vh - 140px)",
                      display: "table",
                      width: "100%",
                    }}
                  >
                    <div
                      className="about-01"
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <div
                        className="horizontal-heading main-about"
                        style={{ color: "#000", marginBottom: '0' }}
                      >
      
                      </div>

                      <div className="container" style={{ marginBottom: "0" }}>


                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          style={{ textAlign: "center" }}
                        >
                          {singleMedia.video && (
                            <video
                              loop
                              src={singleMedia.video?.asset.url}
                              ref={videoRef2}
                              controls
                              controlsList="nodownload"
                              poster={
                                singleMedia.video?.thumbnail?.asset.url
                                  ? singleMedia.video?.thumbnail?.asset.url
                                  : ""
                              }
                              style={{ border: "3px solid #9fd399" }}
                              className={`videoWidth ${
                                singleMedia.video.vidPortrait
                                  ? "video__styles"
                                  : "video__observer-styles"
                              } ${
                                singleMedia.video.vidLandscape
                                  ? "video__landscape-styles"
                                  : ""
                              }`}
                            ></video>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </>
          
        </>
      )}

      {singleMedia.video2 && (
        <>
          
            <>
              <div className="map-color-3">
                <div className="row" style={{ marginRight: "0" }}>
                  <div className="home-headings tools-p-align">
                    <div className="horizontal-heading influencer-name">
                      <h1 className="home-headings-2 influencer-name-h1">
                        <span className="influencer-name-span">{singleMedia.video2.alt}</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <section id="portfolio">
                <div className="isotope-filters">
                  <div className="container"></div>


                  <section
                    id="about2"
                    style={{
                      minHeight: "calc(100vh - 140px)",
                      display: "table",
                      width: "100%",
                    }}
                  >
                    <div
                      className="about-01"
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <div
                        className="horizontal-heading main-about"
                        style={{ color: "#000", marginBottom: '0' }}
                      >
      
                      </div>

                      <div className="container" style={{ marginBottom: "0" }}>


                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          style={{ textAlign: "center" }}
                        >
                          {singleMedia.video2 && (
                            <video
                              loop
                              src={singleMedia.video2?.asset.url}
                              ref={videoRef2}
                              controls
                              controlsList="nodownload"
                              poster={
                                singleMedia.video2?.thumbnail?.asset.url
                                  ? singleMedia.video2?.thumbnail?.asset.url
                                  : ""
                              }
                              style={{ border: "3px solid #9fd399" }}
                              className={`videoWidth ${
                                singleMedia.video2.vidPortrait
                                  ? "video__styles"
                                  : "video__observer-styles"
                              } ${
                                singleMedia.video2.vidLandscape
                                  ? "video__landscape-styles"
                                  : ""
                              }`}
                            ></video>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </>
          
        </>
      )}

      {singleMedia.video3 && (
        <>
          
            <>
              <div className="map-color-3">
                <div className="row" style={{ marginRight: "0" }}>
                  <div className="home-headings tools-p-align">
                    <div className="horizontal-heading influencer-name">
                      <h1 className="home-headings-2 influencer-name-h1">
                        <span className="influencer-name-span">{singleMedia.video3.alt}</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <section id="portfolio">
                <div className="isotope-filters">
                  <div className="container"></div>


                  <section
                    id="about2"
                    style={{
                      minHeight: "calc(100vh - 140px)",
                      display: "table",
                      width: "100%",
                    }}
                  >
                    <div
                      className="about-01"
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <div
                        className="horizontal-heading main-about"
                        style={{ color: "#000", marginBottom: '0' }}
                      >
      
                      </div>

                      <div className="container" style={{ marginBottom: "0" }}>


                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          style={{ textAlign: "center" }}
                        >
                          {singleMedia.video3 && (
                            <video
                              loop
                              src={singleMedia.video3?.asset.url}
                              ref={videoRef2}
                              controls
                              controlsList="nodownload"
                              poster={
                                singleMedia.video3?.thumbnail?.asset.url
                                  ? singleMedia.video3?.thumbnail?.asset.url
                                  : ""
                              }
                              style={{ border: "3px solid #9fd399" }}
                              className={`videoWidth ${
                                singleMedia.video3.vidPortrait
                                  ? "video__styles"
                                  : "video__observer-styles"
                              } ${
                                singleMedia.video3.vidLandscape
                                  ? "video__landscape-styles"
                                  : ""
                              }`}
                            ></video>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </>
          
        </>
      )}

      {singleMedia.video4 && (
        <>
          
            <>
              <div className="map-color-3">
                <div className="row" style={{ marginRight: "0" }}>
                  <div className="home-headings tools-p-align">
                    <div className="horizontal-heading influencer-name">
                      <h1 className="home-headings-2 influencer-name-h1">
                        <span className="influencer-name-span">{singleMedia.video4.alt}</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <section id="portfolio">
                <div className="isotope-filters">
                  <div className="container"></div>


                  <section
                    id="about2"
                    style={{
                      minHeight: "calc(100vh - 140px)",
                      display: "table",
                      width: "100%",
                    }}
                  >
                    <div
                      className="about-01"
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <div
                        className="horizontal-heading main-about"
                        style={{ color: "#000", marginBottom: '0' }}
                      >
      
                      </div>

                      <div className="container" style={{ marginBottom: "0" }}>


                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          style={{ textAlign: "center" }}
                        >
                          {singleMedia.video4 && (
                            <video
                              loop
                              src={singleMedia.video4?.asset.url}
                              ref={videoRef2}
                              controls
                              controlsList="nodownload"
                              poster={
                                singleMedia.video4?.thumbnail?.asset.url
                                  ? singleMedia.video4?.thumbnail?.asset.url
                                  : ""
                              }
                              style={{ border: "3px solid #9fd399" }}
                              className={`videoWidth ${
                                singleMedia.video4.vidPortrait
                                  ? "video__styles"
                                  : "video__observer-styles"
                              } ${
                                singleMedia.video4.vidLandscape
                                  ? "video__landscape-styles"
                                  : ""
                              }`}
                            ></video>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </>
          
        </>
      )}

      {singleMedia.video5 && (
        <>
          
            <>
              <div className="map-color-3">
                <div className="row" style={{ marginRight: "0" }}>
                  <div className="home-headings tools-p-align">
                    <div className="horizontal-heading influencer-name">
                      <h1 className="home-headings-2 influencer-name-h1">
                        <span className="influencer-name-span">{singleMedia.video5.alt}</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <section id="portfolio">
                <div className="isotope-filters">
                  <div className="container"></div>


                  <section
                    id="about2"
                    style={{
                      minHeight: "calc(100vh - 140px)",
                      display: "table",
                      width: "100%",
                    }}
                  >
                    <div
                      className="about-01"
                      style={{ display: "table-cell", verticalAlign: "middle" }}
                    >
                      <div
                        className="horizontal-heading main-about"
                        style={{ color: "#000", marginBottom: '0' }}
                      >
      
                      </div>

                      <div className="container" style={{ marginBottom: "0" }}>


                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          style={{ textAlign: "center" }}
                        >
                          {singleMedia.video5 && (
                            <video
                              loop
                              src={singleMedia.video5?.asset.url}
                              ref={videoRef2}
                              controls
                              controlsList="nodownload"
                              poster={
                                singleMedia.video5?.thumbnail?.asset.url
                                  ? singleMedia.video5?.thumbnail?.asset.url
                                  : ""
                              }
                              style={{ border: "3px solid #9fd399" }}
                              className={`videoWidth ${
                                singleMedia.video5.vidPortrait
                                  ? "video__styles"
                                  : "video__observer-styles"
                              } ${
                                singleMedia.video5.vidLandscape
                                  ? "video__landscape-styles"
                                  : ""
                              }`}
                            ></video>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </>
          
        </>
      )}

      <Statement />
    </>
  );
}

export default Img;
