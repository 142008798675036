import React, {useState, useEffect, useRef} from 'react';
import {
    FaFacebookF, FaHandPointRight,
    FaInstagram, FaRegEye, FaRegHandshake,
    FaTiktok,
    FaTwitter,
    FaYoutube
} from "react-icons/fa";
import {client, urlFor} from "../client";
import {Link, useParams} from "react-router-dom";
import {images} from "../constants";

import './scss/Gallery.scss';

import {Services, Statement, About as AboutSection, Brands} from "../container";


import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {BiCaretRight, BiRightArrow} from "react-icons/bi";
import videoBgMp4 from "../assets/our-stock-is-rising.mp4";
import {AiOutlineLineChart} from "react-icons/ai";
import { IoIosArrowDown } from 'react-icons/io';


import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


function Videos() {
    const [purpose, setPurpose] = useState(null);
    // const [services, setServices] = useState(null);
    const {slug} = useParams();

    const [playing, setPlaying] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const videoRef2 = useRef(null);
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const [videoMobile, setVideoMobile] = useState(false)


    const [videos, setVideos] = useState([]);




    useEffect(() => {
        client.fetch(`*[_type == "videos"] | order(orderRank) {
            name,
            title,
            _id,
            caption,
            description,
            category,
            galleryTitle,
            galleryDesc,
            galleryCaption,
            bio2,
            vidReverse,
            galleryReverse,
            vidPortrait,
            vidLandscape,
            footerNote,
            footer,
            thumbnail{
                asset->{
                    _id,
                    url
                }
            },
            video{
                asset->{
                    _id,
                    url
                }
            },
            videoSquare{
                asset->{
                    _id,
                    url
                }
            },
            thumbnailMobile{
                asset->{
                    _id,
                    url
                }
            },

        }`).then((data) => setVideos(data))
            .catch(console.error)
    }, []);

    console.log(videos);





    useEffect(() => {
        client.fetch(`*[_type == "purpose" && slug.current == 'purpose']{
            name,
            _id,
            slug,
            preheading,
            heading,
            description,
            name2,
            preheading2,
            heading2,
            description2,
            imageUrl{
                asset->{
                    _id,
                    url
                }
            },
            imageUrl2{
                asset->{
                    _id,
                    url
                }
            }
        }`).then((data) => setPurpose(data[0]))
            .catch(console.error)
    }, [slug]);






    // useEffect(() => {
    //     client.fetch(`*[_type == "service"] | order(order asc)`

    //     ).then((data) => {
    //         setServices(data)
    //     })

    // }, []);






    if(!purpose) return (
        <div className="preloader">
            <div className="status"></div>
        </div>
    )
    // if(!services) return <div>Loading.....</div>


    return (
        <>


            <section id="home-4">
                <video className="home-bg-video" src={videoBgMp4} autoPlay loop muted playsInline>
                </video>
                <div id="home-overlay-2"></div>
            </section>



        {videos.map((vid, index) => (
            <div key={vid.name + index}>

            
                <div className="map-color-3" >
                    <div className="row" style={{marginRight: '0'}}>
                        <div className="home-headings tools-p-align">
                            <div className="horizontal-heading influencer-name">
                                <h1 className="home-headings-2 influencer-name-h1"><span className='influencer-name-span'> 
                                {vid.category}
                                </span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="about" style={{height: 'calc(100vh - 140px)', display: 'table', width: '100%'}}>
                    <div className="about-01" style={{display: 'table-cell', verticalAlign: 'middle'}}>
                        <div className="container">
                            <div className="row col-mobile-top" style={{justifyContent: 'center'}}>
                                    {/* Left Side */}
                                    <div className="horizontal-heading main-about" style={{color: '#000'}}>
                                                <h2> <strong ></strong>{vid.title}</h2>
                                            </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12" style={{textAlign: 'center'}}>

                                    {!vid.videoMobile &&
                                                    <video
                                                    loop
                                                    src={vid?.video?.asset.url}
                                                    ref={videoRef2}
                                                    controls
                                                    controlsList="nodownload"
                                                    poster={vid?.thumbnail?.asset.url ? vid?.thumbnail?.asset.url : ''}
                                                    style={{border: '3px solid #9fd399' }}
                                                    className={`videoWidth ${vid.vidPortrait ? 'video__styles' : 'video__observer-styles'} ${vid.vidLandscape ? 'video__landscape-styles' : ''}`}>
                                                    </video>
                                                }


                                        


                                    </div>
                                    
                                    {/* Right Side */}
                                    <div className="col-md-4 col-sm-12 col-mobile" style={{display: 'table', marginTop: '30px'}}>
                                        <div className="about-right" style={{display: 'table-cell', verticalAlign: 'middle'}}>
                

                                            <hr style={{margin: '2rem', border: '2px solid #213805'}}/>


                                            <p className="about-text" style={{marginLeft: '80px', marginRight: '80px'}}>
                                        
                                            <ReactMarkdown 
                                                        children={vid.description} 
                                                        remarkPlugins={[remarkGfm]}   
                                                        />
                                            </p>

                                        
                                                                        
                                            <hr style={{margin: '2rem', border: '2px solid #213805'}}/>
                            

                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                {/*  Arrow Down */}
                <a href="#about2" className="arrow-down smooth-scroll" style={{color: '#213805'}}>
                    <IoIosArrowDown icon="fa-solid fa-angle-down" />
                </a>

                </section>
            </div>
        ))}
            
            <Statement/>



        </>
    );
}

export default Videos;